import GalleryWixCodeApi from '../ProGallery/wixCodeApi';
import { ART_STORE, ALBUMS } from '../../constants';
import CommonViewerScript from '../../common/CommonViewerScript';
import Context from '../../common/ViewerScriptContext';
import ArtStoreManager from './ArtStoreViewerScriptManager';
import CartFedopsLogger from '../../components/cart/cartFedops';

const context = new Context(ART_STORE.SENTRY_DSN);
const storeManager = new ArtStoreManager(context);
const WIDGET_ARRAY = [ART_STORE.GALLERY_WIDGET_ID, ART_STORE.CART_WIDGET_ID];
const galleryWixCodeApi = new GalleryWixCodeApi();

const createAppController = config => {
  const {
    appParams: { instance },
    config: {
      style: { styleParams },
      publicData,
    },
    setProps,
    activeWidgets,
    compId,
    type,
    wixCodeApi,
  } = config;
  if (!WIDGET_ARRAY.includes(type) || storeManager.isIframeStore(wixCodeApi)) {
    return { pageReady: () => {} };
  }
  const storeApi = storeManager.getStoreApi(config);
  const isSSR = wixCodeApi.window.rendering.env === 'backend';
  storeManager.setActiveWidgetIfNeeded(activeWidgets);
  if (ART_STORE.CART_WIDGET_ID === type) {
    const galleryWidgetActive = !!activeWidgets.find(
      widget => widget.type === ART_STORE.GALLERY_WIDGET_ID,
    );
    if (!isSSR && !storeManager.isAlbums(styleParams)) {
      storeManager.initTranslations(setProps);
    }
    const fedopsLogger = new CartFedopsLogger(context);
    fedopsLogger.reportAppLoadStarted();
    return {
      pageReady: () => {
        try {
          storeManager
            .getConnectedProviders(
              instance,
              styleParams, //TODO: remove
              ART_STORE.ARTSTORE_APP_DEFINITION_ID,
            )
            .then(connectedProviders => {
              let initalProps = {
                styleParams,
                publicData,
                storeApi,
                galleryWidgetActive,
                tryToReportAppLoaded: () => fedopsLogger.tryReportAppLoaded(),
              };
              if (connectedProviders) {
                storeManager.initCartForm(storeApi, !galleryWidgetActive);
                initalProps = {
                  ...initalProps,
                  ...storeManager.getInitalProps(
                    { compId, setProps, type },
                    connectedProviders,
                    ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                  ),
                };
              }
              setProps(initalProps);
            });
        } catch (e) {
          console.error(e);
        }
      },
      exports: () => {
        try {
          return galleryWixCodeApi.generateCartApi(({ settings }) => {
            storeManager
              .initStoreWixCodeApi(
                settings,
                styleParams,
                storeApi,
                ALBUMS.ALBUMS_APP_DEF_ID,
                !galleryWidgetActive,
              )
              .then(({ connectedProviders, additionalProviderParams }) => {
                setProps(
                  storeManager.getInitalProps(
                    { compId, setProps, type },
                    connectedProviders,
                    ALBUMS.ALBUMS_APP_DEF_ID,
                    additionalProviderParams,
                  ),
                );
              });
          });
        } catch (e) {}
      },
    };
  } else {
    const commonViewerScript = new CommonViewerScript(
      context,
      config,
      ART_STORE.ARTSTORE_APP_DEFINITION_ID,
      ART_STORE.GALLERY_WIDGET_ID,
      true,
    );
    const tryToReportAppLoaded = commonViewerScript.getTryToReportAppLoaded();
    const wixCodeItemsPromise = storeManager.getDeferredPromise();
    return {
      pageReady: () => {
        try {
          const renderGallery = viewPortState => {
            let notInView = false;
            if (viewPortState && !viewPortState.in) {
              //notInView can be true only in SSR.
              //tryToReportAppLoaded for this case will be called later here in "if (commonViewerScript.isSSR())"
              notInView = true;
            }
            const initPromise = new Promise((resolve, reject) => {
              try {
                storeManager
                  .getConnectedProviders(
                    instance,
                    styleParams, //TODO: remove
                    ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                  )
                  .then(connectedProviders => {
                    if (connectedProviders) {
                      commonViewerScript.getItemsPromise().then(() => {
                        storeManager.initCartForm(storeApi);
                        resolve({ connectedProviders: connectedProviders });
                      });
                    } else {
                      const skipItems = storeManager.shouldSkipItems(
                        wixCodeApi,
                      );
                      skipItems
                        ? resolve({})
                        : wixCodeItemsPromise.promise.then(() => resolve({}));
                    }
                  });
              } catch (e) {
                reject(e);
              }
            });
            return initPromise
              .catch(e => {
                commonViewerScript.sentryReport(e);
                console.error('Waiting for promises failed', e);
              })
              .then(({ connectedProviders }) => {
                let initalProps = {
                  ...commonViewerScript.getCommonGalleryProps(true),
                  storeApi,
                  onItemClicked: commonViewerScript.getOnItemClickedFunc(false),
                  watermarkData: commonViewerScript.getWatermark(),
                  onLinkNavigation: commonViewerScript.getOnLinkNavigationFunc(),
                  cssBaseUrl: config.appParams.baseUrls.staticsBaseUrl,
                  styleParams: config.config.style.styleParams,
                  notInView,
                };
                if (connectedProviders) {
                  initalProps = {
                    ...initalProps,
                    ...storeManager.getInitalProps(
                      { compId, setProps, type },
                      connectedProviders,
                      ART_STORE.ARTSTORE_APP_DEFINITION_ID,
                    ),
                  };
                }
                setProps(initalProps);
                if (commonViewerScript.isSSR()) {
                  tryToReportAppLoaded(true);
                }
                return commonViewerScript.getWarmupData();
              });
          };

          return commonViewerScript
            .getViewPortPromise()
            .then(renderGallery)
            .catch(error => {
              console.error(error);
              commonViewerScript.sentryReport({
                errorMessage: 'viewportPromise rejected!',
                error,
              });
              tryToReportAppLoaded();
            });
        } catch (e) {
          console.error(e);
          commonViewerScript.sentryReport(e);
        }
      },
      exports: () => {
        try {
          return galleryWixCodeApi.generateApi({
            proGalleryStore: commonViewerScript.getPgStore(),
            setNewItems: items => {
              const setNewItems = commonViewerScript.getSetNewItemsFunc();
              setNewItems(items);
              wixCodeItemsPromise.resolve();
            },
            setNewStyleParams: sp => commonViewerScript.setNewStyleParams(sp),
            setClickedIdx: clickedIdx =>
              commonViewerScript.setClickedIdx(clickedIdx),
            setNewSettings: settings => {
              const { watermark, galleryId } = settings;
              const store = commonViewerScript.getPgStore();
              watermark && setProps({ watermarkWixCode: watermark });
              galleryId && (store.galleryId = galleryId);
              store.setSettings(settings);
              if (!commonViewerScript.isSSR()) {
                storeManager
                  .initStoreWixCodeApi(
                    settings,
                    styleParams,
                    storeApi,
                    ALBUMS.ALBUMS_APP_DEF_ID,
                  )
                  .then(({ connectedProviders, additionalProviderParams }) => {
                    setProps(
                      storeManager.getInitalProps(
                        { compId, setProps, type },
                        connectedProviders,
                        ALBUMS.ALBUMS_APP_DEF_ID,
                        additionalProviderParams,
                      ),
                    );
                  });
              }
            },
          });
        } catch (e) {
          commonViewerScript.sentryReport(e);
        }
      },
    };
  }
};

export const wrappedFunctions = {
  initAppForPage: CommonViewerScript.getInitAppForPageFunc(context),
  createControllers: CommonViewerScript.getCreateControllers(
    createAppController,
    context.getSentry(),
  ),
};
