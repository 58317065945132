import getArtStoreApi from './artStoreApi';
import { ARTSTORE_PUBSUB_ENUMS, ART_STORE } from '../../constants';
import wixAppsApi from './wixAppsApi';
import ProGalleryStore from '../../common/ProGalleryStore';
import translationUtils from '@wix/photography-client-lib/dist/src/utils/translationUtils';

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

export default class ArtStoreManager {
  constructor(context) {
    this.context = context;
    this.pagesState = {
      showCheckout: false,
      showCart: false,
      showShopEditor: false,
    };
    this.cartStorageKey = 'artStoreCartCount';
    this.activeStoreWidget = null;
  }

  shouldSkipItems = wixCodeApi => {
    return !!wixCodeApi.location.query.skipItems;
  };

  setActiveWidgetIfNeeded(activeWidgets) {
    if (!this.activeStoreWidget) {
      const galleryWidget = activeWidgets.find(
        widget => widget.type === ART_STORE.GALLERY_WIDGET_ID,
      );
      const cartWidget = activeWidgets.find(
        widget => widget.type === ART_STORE.CART_WIDGET_ID,
      );
      this.activeStoreWidget = galleryWidget || cartWidget;
    }
  }

  getDeferredPromise() {
    return new Deferred();
  }

  isIframeStore(wixCodeApi) {
    // remove when fullscreen share use OOI artstore
    return wixCodeApi.location.url.indexOf('fullscreen-store-page') > -1;
  }

  changeActiveStoreWidget(widget) {
    if (widget.compId !== this.activeStoreWidget.compId) {
      this.activeStoreWidget = widget;
    }
  }

  openCart() {
    if (this.activeStoreWidget && !this.pagesState.showCart) {
      this.pagesState.showCart = true;
      this.activeStoreWidget.setProps({ pagesState: this.pagesState });
    }
  }

  getSectionsUrl() {
    const context = this.context.getContext();
    const getSectionUrl = sectionId =>
      context.scopedGlobalSdkApis.site.getSectionUrl({ sectionId });
    return {
      thank_you_page: getSectionUrl('thank_you_page'),
      checkout_page: getSectionUrl('checkout_page'),
    };
  }

  getAdditionalProviderParams(
    wixCodeData,
    appDefId = ART_STORE.ARTSTORE_APP_DEFINITION_ID,
  ) {
    const appApi = wixAppsApi[appDefId];
    return appApi.getAdditionalParams(wixCodeData);
  }

  getInstanceFromSettings(
    settings,
    appDefId = ART_STORE.ARTSTORE_APP_DEFINITION_ID,
  ) {
    const appApi = wixAppsApi[appDefId];
    return appApi.getInstanceFromSettings(settings);
  }

  isAlbums(sp) {
    return !!(sp.booleans && sp.booleans.ignoreSavedConnectedProviders);
  }

  async getConnectedProviders(
    instance,
    styleParams, // TODO: remove
    appDefId = ART_STORE.ARTSTORE_APP_DEFINITION_ID,
  ) {
    if (
      this.isAlbums(styleParams) &&
      appDefId === ART_STORE.ARTSTORE_APP_DEFINITION_ID
    ) {
      return Promise.resolve(null);
    }
    // end of remove
    const baseUrl = this.getBaseUrl();
    const appApi = wixAppsApi[appDefId];
    const connectedProviders = await appApi.getConnectedProviders(
      instance,
      baseUrl,
    );
    return Promise.resolve(connectedProviders);
  }

  getLocale() {
    const { scopedGlobalSdkApis } = this.context.getContext();
    if (
      scopedGlobalSdkApis &&
      scopedGlobalSdkApis.site &&
      scopedGlobalSdkApis.site.language
    ) {
      return scopedGlobalSdkApis.site.language;
    } else {
      return 'en';
    }
  }

  initTranslations(setProps) {
    const locale = this.getLocale();
    const translationsPromise = ProGalleryStore.getTranslations(locale);
    translationsPromise.then(translationsRaw => {
      translationUtils.setTranslations(translationsRaw);
      setProps({ translations: translationsRaw });
    });
  }

  initCartForm(storeApi, isActiveCart = true, cartFormQueryParams = undefined) {
    storeApi.initCartForm(cartFormQueryParams);
    if (isActiveCart) {
      storeApi.subscribeShowCart();
    }
  }

  getChangeStoreFrameStateFunc(setProps) {
    return (frame, newFrameState) => {
      const pagesState = this.pagesState;
      switch (frame) {
        case ARTSTORE_PUBSUB_ENUMS.CART:
          if (newFrameState) {
            this.openCart();
            return;
          }
          pagesState.showCart = newFrameState;
          break;
        case ARTSTORE_PUBSUB_ENUMS.CHECKOUT:
          if (newFrameState) {
            pagesState.showCart = false;
            pagesState.showShopEditor = false;
          }
          pagesState.showCheckout = newFrameState;
          break;
        case ARTSTORE_PUBSUB_ENUMS.EDITOR:
          if (newFrameState) {
            pagesState.showCheckout = false;
            pagesState.showCart = false;
          }
          pagesState.showShopEditor = newFrameState;
          break;
        default:
          break;
      }
      this.pagesState = pagesState;
      setProps({ pagesState });
    };
  }

  getInitalProps(
    { compId, setProps, type },
    connectedProviders,
    appDefId,
    additionalProviderParams = {},
  ) {
    const baseSectionsUrl = this.getSectionsUrl();
    const sectionsUrls = additionalProviderParams.freeArtStore
      ? {}
      : wixAppsApi[appDefId].getSectionsUrlWithParams(
          baseSectionsUrl,
          additionalProviderParams,
          connectedProviders,
        );
    const { platformServices } = this.context.getContext();
    return {
      changeStoreFrameState: this.getChangeStoreFrameStateFunc(setProps),
      connectedProviders,
      additionalProviderParams,
      pagesState: {
        showCheckout: false,
        showCart: false,
        showShopEditor: false,
      },
      setActiveStoreWidget: () =>
        this.changeActiveStoreWidget({ compId, setProps, type }),
      visitorId: platformServices.bi.visitorId,
      metaSiteId: platformServices.bi.metaSiteId,
      cartItemsData: {
        cartItems: [],
        cartItemsCount: this.readStorageCount() || 0,
      },
      sectionsUrls,
      locale: this.getLocale(),
      ready: true,
      baseUrl: this.getBaseUrl(),
    };
  }

  getBaseUrl() {
    const baseUrl = this.context.getContext().scopedGlobalSdkApis.location
      .baseUrl;
    const baseUrlParts = baseUrl.split('/');
    return baseUrlParts.slice(0, 3).join('/');
  }

  updateStorageCount(newCount) {
    const {
      platformUtilitiesApi: { storage },
    } = this.context.getContext();
    storage.memory.setItem(this.cartStorageKey, newCount);
  }

  readStorageCount() {
    const {
      platformUtilitiesApi: { storage },
    } = this.context.getContext();
    return storage.memory.getItem(this.cartStorageKey);
  }

  async initStoreWixCodeApi(
    settings,
    sp,
    storeApi,
    appDefId,
    isActiveCart = true,
  ) {
    const instance = this.getInstanceFromSettings(settings, appDefId);
    const additionalProviderParams = this.getAdditionalProviderParams(
      settings,
      appDefId,
    );
    if (additionalProviderParams && additionalProviderParams.freeArtStore) {
      return Promise.resolve({ additionalProviderParams });
    }
    return this.getConnectedProviders(instance, sp, appDefId).then(
      connectedProviders => {
        const queryParams = wixAppsApi[appDefId].getAdditionalQueryParams(
          additionalProviderParams,
        );
        this.initCartForm(storeApi, isActiveCart, queryParams);
        return { connectedProviders, additionalProviderParams };
      },
    );
  }

  getStoreApi(config) {
    const {
      platformUtilitiesApi: { pubSub },
    } = this.context.getContext();
    const { compId, setProps, wixCodeApi } = config;
    const updateStorageIfNeeded = newCount => {
      if (!this.count || newCount !== this.count) {
        this.count = newCount;
        this.updateStorageCount(newCount);
      }
    };
    return getArtStoreApi(
      pubSub,
      wixCodeApi.window.openPersistentPopup,
      compId,
      setProps,
      updateStorageIfNeeded,
      () => this.openCart(),
    );
  }
}
