import { ART_STORE, ALBUMS } from '../../constants';
//copy of pro-gallery-artstore-statics wixAppsApi - TODO: delete
const wixProvidersArray = [ART_STORE.PROVIDERS.WIX, ART_STORE.PROVIDERS.LOCAL];
const getProviderId = connectedProviders =>
  connectedProviders[Object.keys(connectedProviders)[0]].providerId;
const fetchConnectedProvider = async url => {
  try {
    const response = await fetch(url);
    if (response.status === 200) {
      const data = await response.json();
      if (data && data.providerId) {
        return { [data.providerId]: data };
      }
    }
  } catch (err) {
    console.error(err);
  }
  return null;
};

export default {
  [ALBUMS.ALBUMS_APP_DEF_ID]: {
    getAdditionalParams: ({ albumId, pricelistId, freeArtStore }) => {
      return {
        srcId: albumId,
        pricelistId,
        appDefId: ALBUMS.ALBUMS_APP_DEF_ID,
        isAlbumStore: true, //TODO: should be removed after all providers supports this'
        freeArtStore,
      };
    },
    getConnectedProviders: async (instanceId, _baseUrl) => {
      const url = `https://www.wix.com/_api/albums-node-server/getAlbumConnectedProvidersByArtStoreInstanceId?instanceId=${instanceId}`;
      return await fetchConnectedProvider(url);
    },
    getInstanceFromSettings: settings => settings.albumId || settings.srcId,
    getAdditionalQueryParams: providerParams => {
      const { appDefId, srcId } = providerParams;
      return `srcId=${srcId}&appDefId=${appDefId}`;
    },
    getSectionsUrlWithParams: (
      baseSectionsUrl,
      providerParams,
      connectedProviders,
    ) => {
      const providerId = getProviderId(connectedProviders);
      if (!wixProvidersArray.includes(providerId)) {
        const { appDefId, srcId } = providerParams;
        const { thank_you_page, checkout_page } = baseSectionsUrl;
        const queryParams = `?srcId=${srcId}&appDefId=${appDefId}`;
        return {
          thank_you_page: {
            url: thank_you_page.url + queryParams,
          },
          checkout_page: {
            url: checkout_page.url + queryParams,
          },
        };
      }
      return baseSectionsUrl;
    },
  },
  [ART_STORE.ARTSTORE_APP_DEFINITION_ID]: {
    getAdditionalParams: () => ({}),
    getAdditionalQueryParams: () => '',
    getInstanceFromSettings: () => {},
    getConnectedProviders: async (instance, baseUrl) => {
      const url = `${baseUrl}/_api/pro-gallery-webapp/v1/store/providers?instance=${instance}`;
      return await fetchConnectedProvider(url);
    },
    getSectionsUrlWithParams: (baseSectionsUrl, _params, _connectedProviders) =>
      baseSectionsUrl,
  },
};
